.App {
  text-align: center;
    padding: 1em;
}

.ElementContainer {
    display: flex;
    /*flex-flow: column;*/
    flex-direction: column-reverse;
    margin-bottom: 2em;
}

.GameStatus {
    position: absolute;
    background-color: darkred;
    color: #fff;
    font-size: 22px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    margin: auto;
    padding: 7em;
    opacity: 0.8;
}

.GameStatus button {
    margin-top: 1em;
}

.DicesAside {
    max-height: 60px;
    height: 80px;
    min-width: 300px;
    width: 500px;
    margin: 0 auto;
    background-color: rgba(255,255,200, 0.4);;
    color: white;
    padding: 1em;
    text-shadow: 1px 1px #333333;
    position: relative;
    border-radius: 10px;
    border: 1px solid #333;
    padding-bottom: 50px;
}

.DicesAsideScore {
    position: absolute;
    bottom: 1em;
    text-align: center;
    width: 100%;
}

.DicesAsideScoreLabel {
    display: inline-block;
    margin-left: 1em;
}

.DiceStack {
    flex: 1 1 100%;
    position: relative;
    padding: 1em;
    width: 80vw;
    height: 30vh;
    margin: 0 auto;
}

.DiceStack #dice0 { position: absolute; top: 10px; left: 35%; }
.DiceStack #dice1 { position: absolute; top: 10px; left: 65%; }
.DiceStack #dice2 { position: absolute; top: 75px; left: 25%; }
.DiceStack #dice3 { position: absolute; top: 75px; left: 50%; }
.DiceStack #dice4 { position: absolute; top: 75px; left: 40%; }
.DiceStack #dice5 { position: absolute; top: 75px; left: 70%; }
.DiceStack #dice6 { position: absolute; top: 140px; left: 40%; }
.DiceStack #dice7 { position: absolute; top: 140px; left: 60%; }

.Dice {
    background-color: whitesmoke;
    /*background-color: aliceblue;*/
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 40px 40px !important;
    display: inline-block;
    margin: .5em;

    width: 45px;
    height: 45px;
    border-radius: 10px;
    border: 1px solid #999;
    line-height: 1.9em;
}

.Dice.chosenfalse {
    opacity: 0.5;
}

.Dice:hover:not(.chosenfalse) {
    cursor: pointer;
    background-color: white;
}

.Dice.worm { background-image: url("images/worm.png") }
.Dice.d1 { background-image: url("images/d1.png") }
.Dice.d2 { background-image: url("images/d2.png") }
.Dice.d3 { background-image: url("images/d3.png") }
.Dice.d4 { background-image: url("images/d4.png") }
.Dice.d5 { background-image: url("images/d5.png") }

.PlayerTurnModal {
    width: 80vw;
    max-width: 900px;
    height: 20vh;
    max-height: 300px;
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.PlayerContainer {
    display: flex;
}

.Player {
    flex: 1 1 100%;
    border-radius: 50%;
    border: 2px solid #333333;
}

.Player.PlayerTurntrue {
    background-color: white;
}

.DominoStack {
    display: flex;
}

.Domino {
    flex: 1 1 auto;
    background-color: navajowhite;
    border-radius: 12px;
    border: 2px solid #333333;
    padding: .2em;
}

.DominoCanBeChosentrue {
    background-color: white;
}

.Domino>h2 {
    border-bottom: 2px solid #333333;
}

