body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: steelblue;
}

.App>h1 {
  margin-top: .2em;
  background: url("images/regenwormen.png") steelblue no-repeat top center;
  background-size: 300px;
  height: 90px;
  content: '';
  color: transparent;
  /*background-blend-mode: lighten;*/
}

button {
  box-sizing: border-box;
  border: 2px solid transparent;
  background-color: rgb(0, 196, 111);
  border-radius: 60px;
  color: rgb(255, 255, 255);
  cursor: auto;
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
  line-height: 25px;
  padding: .5em 2em;
  text-align: center;
  text-decoration: none;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease;
}

button:hover {
  background-color: #00b98b;
  cursor: pointer;
}

button:active {
  background-color: #999999 !important;
}

button:disabled {
  background-color: #999999 !important;
}

button:focus {
  border: 2px solid #61dafb;
  border-radius: 60px;
  outline: none;
}

footer {

}

